import { Box, Grid, Link, Typography } from "@mui/material";
import { IconBlockedCardHuge } from "atoms/IconBlockedCardHuge";
import { IconBlockedCardSmall } from "atoms/IconBlockedCardSmall/IconBlockedCardSmall";
import { useIsMobile } from "hooks/useIsMobile";

const BlockedScreen = () => {
  const { isMobile } = useIsMobile();

  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      component="main"
      container
      px={{ xs: 16, xl: 86 }}
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Box
        p={{ xs: "24px 0 80px", md: "32px 30.5px" }}
        component="div"
        display="flex"
        flexDirection="column"
        textAlign={isMobile ? "left" : "center"}
      >
        <Typography
          variant="text_9"
          color="gray.b900"
          fontWeight="600"
          mb={isMobile ? 8 : 16}
        >
          Карта заморожена
        </Typography>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="text_19"
            color="gray.b800"
            style={{ whiteSpace: "pre-wrap" }}
            mb={40}
          >
            Вы ввели неверные данные, ваша карта заморожена
          </Typography>

          {isMobile ? <IconBlockedCardSmall /> : <IconBlockedCardHuge />}

          <Typography
            mt={isMobile ? 24 : 20}
            variant="text_19"
            color="gray.b700"
            maxWidth={556}
            style={{ whiteSpace: "pre-wrap" }}
          >
            Для разморозки карты позвоните в техподдержку банка по телефону
            {isMobile ? "\n" : " "}
            <Link
              href="tel:+78002004567"
              variant="text_19"
              color="#739b67"
              fontWeight={400}
              // component="div"
              sx={{
                textDecoration: "none",
              }}
            >
              + 7 (800) 200-45-67
            </Link>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default BlockedScreen;
