import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import theme from "theme";
import { AuthContainer } from "containers";
import { getSecondsPluralForms } from "utils";
import { SmsInput } from "molecules/SmsInput/SmsInput";
import { useCounter } from "hooks/useCounter";
import {
  authActions,
  checkPassword,
  retryOneTimePassword,
} from "store/slices/auth";
import { authSelector } from "store";
import { useDispatch, useSelector } from "react-redux";
import {
  BackButtonContainer,
  SmallBackButton,
} from "components/SmallBackButton";
import { Button } from "components/Button";

export const SecondScreen = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>("");
  const { count, restartCounter } = useCounter();
  const { isLoading, error, phone } = useSelector(authSelector);
  const secondsWord = getSecondsPluralForms(count);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
  };
  const handleConfirm = () => {
    dispatch(checkPassword(code));
  };

  const handleRetryOneTimePassword = () => {
    dispatch(retryOneTimePassword());
    restartCounter();
  };

  const maskedNumber = phone?.replace(
    /^(\d+)(\d{2})(\d{2})$/,
    (_, prefix, secondPart, thirdPart) =>
      "*".repeat(3) + " " + secondPart + " " + thirdPart
  );

  const text = () => {
    return (
      <Typography
        variant="text_5"
        color="gray.b500"
        maxWidth={306}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {maskedNumber}
      </Typography>
    );
  };

  return (
    <>
      <BackButtonContainer>
        <SmallBackButton
          clickHandler={() => dispatch(authActions.setRestoreScreen("initial"))}
        />
      </BackButtonContainer>
      <AuthContainer
        title="Восстановить пароль"
        subtitle={`${maskedNumber}`}
        text={text()}
        separation={true}
      >
        <Box
          component="form"
          autoComplete="off"
          sx={{
            backgroundColor: theme.palette.gray.b50,
          }}
          px={{ xs: 20, lg: 30.5 }}
          py={32}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          border={`1px solid ${theme.palette.gray.b100}`}
          borderRadius={24}
        >
          <Box>
            <SmsInput
              placeholder="Код из SMS"
              label="Код"
              name="code"
              value={code}
              onChange={handleChange}
              // @ts-ignore
              error={error}
            />
          </Box>
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              rowGap={count > 0 ? 44 : 12}
              mt={42}
            >
              {count === 0 ? (
                <Button
                  onClick={handleRetryOneTimePassword}
                  variant="secondary"
                  title="Отправить код повторно"
                />
              ) : (
                <Box minHeight={48}>
                  <Typography variant="text_5" color="gray.b400">
                    Вы сможете отправить код повторно через{" "}
                    <Typography variant="text_5" color="blue.b400">
                      {count} {secondsWord}.
                    </Typography>
                  </Typography>
                </Box>
              )}
              <Button
                onClick={handleConfirm}
                variant="primary"
                disabled={code.length < 4}
                isLoading={isLoading}
                title="Продолжить"
              />
            </Box>
          </Box>
        </Box>
      </AuthContainer>
    </>
  );
};
