import {
  ChatBlock,
  ErrorNotification,
  Files,
  NotificationUnavailable,
  SendMessage,
  InfiniteScroll,
} from "./components";
import { Box, Link, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions, getChatMessages, markRead } from "store/slices/chat";
import { chatSelector, store } from "store";
import { Button, Loader } from "components";
import { useGetChatMessages } from "./hooks";
import { getSortedMessages } from "./utils";
import { ChatMessageDto, ChatMessageTypeEnum } from "../../api/notification";
import { IconArrowDown } from "atoms/IconArrowDown";
import { sleep } from "utils";
import { IconChatError } from "atoms/IconChatError";
import { OperatorRating } from "./components/OperatorRating";
import { useIsMobile } from "hooks/useIsMobile";
import { IconPhone } from "atoms/IconPhone/IconPhone";
import { IconRefresh } from "atoms/IconRefresh/IconRefresh";

export interface ChatCreateAttachProps {
  id?: string;
  fileImage?: string;
  fileName?: string;
  fileType?: string;
  fileSize?: string;
}

export const ChatWidget = () => {
  const lastBlockRef = useRef<HTMLDivElement>(null);
  const rootSentinelRef = useRef<HTMLDivElement>(null);
  const prevScrollHeightRef = useRef<number>(0);
  const dispatch = useDispatch<typeof store.dispatch>();
  const chatRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const { isMobile } = useIsMobile();
  const scrollToBottom = () => {
    //@ts-ignore
    lastBlockRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    const div = rootSentinelRef?.current;

    const cur = div !== null ? div?.scrollHeight - div?.scrollTop : 0;
    if (cur < 1000) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };

  const {
    messages,
    isLoading,
    paginationPage,
    chatMessagesState,
    files,
    unavailableError,
    isSizeError,
    isSizeErrorVisible,
    initSumMessages,
    latestPage,
    isMessageSending,
    chatError,
    showOperatorRating,
    idRatingMessage,
    newMessageCounter,
  } = useSelector(chatSelector);

  useGetChatMessages();

  useEffect(() => {
    if (chatMessagesState === "firstMessagesLoaded" && !isLoading) {
      sleep().then(scrollToBottom);
    }
  }, [messages, chatMessagesState, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(chatActions.setChatMessageState("initial"));
      dispatch(chatActions.clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    const unReaded = Object.keys(messages)
      .map((key) => messages[key])
      .flat()
      .filter((msg) => {
        if (
          msg.type === ChatMessageTypeEnum.RatingRequest &&
          msg.id !== idRatingMessage
        ) {
          dispatch(chatActions.setShowOperatorRating(true));
          dispatch(chatActions.setIdRatingMessage(msg.id));
          dispatch(chatActions.setOperatorRatingTitle(msg.text));
        }
        if (!msg.read && msg.user) {
          return msg;
        }
      })
      .map(({ id }) => id);
    if (unReaded.length > 0) {
      //@ts-ignore
      dispatch(markRead(unReaded)).then(() => {
        // scrollToBottom();
      });
    }
  }, [messages, dispatch, idRatingMessage]);

  const handleLoadMore = useCallback(() => {
    const scrollBefore = rootSentinelRef.current?.scrollHeight || 0;
    if (!isLoading) {
      dispatch(chatActions.setChatMessageState("scrolling"));
      if (paginationPage && paginationPage >= 1) {
        dispatch(getChatMessages({ page: paginationPage - 1, size: 20 }))
          .unwrap()
          .then(({ page = 0, messages }) => {
            dispatch(chatActions.setPaginationPage(page));
            dispatch(chatActions.setPaginationMessages(messages || {}));
            const scrollHeight = rootSentinelRef.current?.scrollHeight || 0;
            rootSentinelRef.current?.scrollTo(
              0,
              scrollHeight - (prevScrollHeightRef.current || scrollBefore)
            );

            prevScrollHeightRef.current = scrollHeight;
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoading, paginationPage]);

  const handleErrorClose = () => {
    dispatch(chatActions.setIsSizeErrorClosed());
    dispatch(chatActions.clearIsFileWithErrorSize());
  };

  const isInitialLoading = isLoading && chatMessagesState === "initial";
  const isSubLoading =
    initSumMessages < 20 && latestPage !== null && latestPage >= 1;

  if (isInitialLoading || isSubLoading) {
    return (
      <Box
        width="100%"
        height="calc(100% - 120px)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  const filteredMessages = (messages: ChatMessageDto[]) => {
    return messages.filter(
      ({ text, attachments }) => text || attachments?.length
    );
  };

  return (
    <Box
      position="fixed"
      bottom={0}
      padding={{
        xs: "16px",
        lg: "32px",
      }}
      pt={0}
      maxWidth={490}
      width="100%"
      boxSizing="border-box"
      paddingBottom={{ xs: isMobile ? "40px" : "25px" }}
      bgcolor={isMobile ? "white" : "transparent"}
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={{
          xs: "calc(100svh - 145px)",
        }}
        gap={10}
        ref={chatRef}
      >
        {showOperatorRating && <OperatorRating />}
        {chatError && isScrolled && (
          <Box
            onClick={scrollToBottom}
            sx={{
              cursor: "pointer",
              position: "absolute",
              bottom: 85,
              right: 0,
            }}
          >
            <IconArrowDown count={Number(newMessageCounter)} />
          </Box>
        )}
        {chatError ? (
          <InfiniteScroll
            lastBlockRef={lastBlockRef}
            rootSentinelRef={rootSentinelRef}
            isLoading={isLoading}
            onLoadMore={handleLoadMore}
            onScroll={handleScroll}
          >
            {getSortedMessages(messages).map(([date, messages]) => (
              <ChatBlock
                key={date}
                date={date}
                messages={filteredMessages(messages)}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <IconChatError />
            <Typography
              mt={22}
              mb={8}
              sx={{
                color: "var(--main-color-notification-title)",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Чат поддержки временно
              <br />
              недоступен
            </Typography>
            <Typography
              sx={{
                color: "var(--main-color-notification-title)",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              Уже работаем над этой проблемой
            </Typography>

            <Typography
              variant="text_5"
              color="gray.b500"
              fontWeight={400}
              display="flex"
              alignItems="center"
              sx={{ columnGap: "4px", marginTop: isMobile ? "24px" : "20px" }}
            >
              <IconPhone
                size={16}
                color="var(--main-color-text-secondary-unactive)"
              />{" "}
              Служба поддержки
            </Typography>
            <Link
              variant="text_5"
              color="#739b67"
              fontWeight="500"
              underline="none"
              href="tel:+7 (800) 200 45 67"
            >
              +7 (800) 200 45 67
            </Link>
            <Button
              variant="primary"
              fullWidth
              startIcon={<IconRefresh />}
              style={{ position: "absolute", bottom: 20 }}
              onClick={handleLoadMore}
              title="Обновить"
            />
          </Box>
        )}

        {chatError && (
          <Box
            display="flex"
            flexDirection="column"
            gap={files.length ? 10 : 0}
          >
            <SendMessage lastBlockRef={lastBlockRef} />
            {!isMessageSending && <Files files={files} />}
          </Box>
        )}
      </Box>

      {chatError && chatRef && unavailableError && (
        <NotificationUnavailable
          title={unavailableError.title || ""}
          refElement={chatRef.current}
          description={unavailableError.subtitle || ""}
          withInfoIcon={true}
          onClose={() => dispatch(chatActions.clearUnavailableError())}
        />
      )}

      {chatError &&
        chatRef &&
        !unavailableError &&
        Object.values(messages).length <= 0 && (
          <NotificationUnavailable
            title="Начните чат с оператором"
            refElement={chatRef.current}
            description="Задайте ваш вопрос и оператор ответит вам"
          />
        )}
      {chatError && isSizeError && isSizeErrorVisible && (
        <ErrorNotification
          refElement={chatRef.current}
          description="Размер файла не должен превышать 5 Mb"
          onClose={handleErrorClose}
        />
      )}
    </Box>
  );
};
