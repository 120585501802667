import { Link } from "react-router-dom";
import { BackButtonArrow } from "components/BackButtonArrow";
import icon from "./assets/icon.png";
import styles from "./style.module.css";
import { useIsMobile } from "hooks/useIsMobile";
import { Button } from "components/Button";

export const ForgotHint = ({ handleBack }: { handleBack: () => void }) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      style={{
        paddingTop: isMobile ? "0px" : "150px",
        paddingBottom: isMobile ? "0px" : "150px",
      }}
    >
      <div className={styles.container}>
        <div className={styles.backBtn}>
          <BackButtonArrow onClick={handleBack} />
        </div>
        <div className={styles.titleBlock}>
          <img src={icon} className={styles.icon} />
          <p className={styles.title}>
            Если вы забыли пароль или логин,
            <br />
            то для входа в приложение вам потребуется заново зарегистрироваться
          </p>
          <p className={styles.description}>
            Понадобится карта банка и телефон, на который был зарегистрирован
            профиль
          </p>
        </div>
        <Link to="/sign-up" className={styles.link}>
          <Button variant="primary" title="Продолжить" />
        </Link>
      </div>
    </div>
  );
};
