import { useCallback, useLayoutEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { setMainCard } from "store/slices/cardsSlice";
import { RootState } from "store";

import { CardDto, CardLimitConfirmResponse, CardStatus } from "api/account";

import { CardDetails } from "components/CardDetails/CardDetails";
import { CardsBottomSheet } from "components/CardsBottomSheet/CardsBottomSheet";
import CardSelectModal from "components/CardSelectModal/CardSelectModal";

interface Props {
  defaultCard?: CardLimitConfirmResponse;
  activeCard?: CardDto;
  hideTitle?: boolean;
}

const CardSelect = ({ defaultCard, hideTitle, activeCard }: Props) => {
  const { isMobile } = useIsMobile();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    cards: { mainCard, allCards },
  } = useSelector((state: RootState) => state);

  const handleClickOutside = () => {
    setIsModalOpen(false);
  };

  const switchCard = useCallback(
    (card: CardDto) => {
      dispatch(setMainCard(card));
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    if (defaultCard) switchCard(defaultCard);
  }, [defaultCard, switchCard]);

  const card = mainCard.status === CardStatus.Active ? mainCard : activeCard;

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        color: "red",
        rowGap: "8px",
      }}
    >
      {!hideTitle && (
        <Typography
          fontWeight={400}
          sx={{
            fontSize: "16px",
            lineHeight: "20px",
            color: "var(--main-color-text-title)",
          }}
        >
          С карты
        </Typography>
      )}
      <Box
        sx={{
          position: "relative",
        }}
        // onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <CardDetails
          card={card}
          hasMultipleCards={allCards.length > 1}
          onClick={() => setIsModalOpen(!isModalOpen)}
        />

        {allCards.length > 1 && (
          <>
            {isMobile && (
              <CardsBottomSheet
                isOpen={isModalOpen}
                cards={allCards}
                onClick={switchCard}
                onClose={handleClickOutside}
              />
            )}
            {!isMobile && (
              <CardSelectModal
                cards={allCards}
                mainCard={mainCard}
                onClick={switchCard}
                onClose={handleClickOutside}
                isOpen={isModalOpen}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CardSelect;
