import React from "react";
import { FirstScreen } from "./FirstScreen";
import { SecondScreen } from "./SecondScreen";
import { ThirdScreen } from "./ThirdScreen";
import { FourthScreen } from "./FourthScreen";
import { useSelector } from "react-redux";
import { authSelector } from "store";
import { SecondCVVScreen } from "./SecondCVVScreen";
import { FrozenScreen } from "organisms/Registration/FrozenScreen";

export type RecoveryPasswordScreens =
  | "initial"
  | "confirmation"
  | "newPassword"
  | "cvv"
  | "frozen"
  | "success";

export const RecoveryPassword = () => {
  const { resetScreen } = useSelector(authSelector);

  const screens: Record<RecoveryPasswordScreens, React.ReactNode> = {
    initial: <FirstScreen />,
    confirmation: <SecondScreen />,
    cvv: <SecondCVVScreen />,
    newPassword: <ThirdScreen />,
    success: <FourthScreen />,
    frozen: <FrozenScreen />,
  };

  return screens[resetScreen];
};
