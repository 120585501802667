import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { Limits } from "./Limits";
import { Transfers } from "./Transfers";
import { History } from "./History";
import { Promotion } from "./Promotion";
import { Main } from "./Main";
import { systemActions } from "store/slices/system";
import { systemSelector, chatSelector } from "store";
import IconChat from "components/Icons/IconChat";
import { useIsMobile } from "hooks/useIsMobile";
import { MobileTabVariants } from "store/slices/system/slice";
import { EventSourcePolyfill } from "event-source-polyfill";
import { chatActions } from "../../../store/slices/chat";
import env from "../../../env";
import { authApi } from "api";
import { useIsPWA } from "hooks/useIsPWA";
import { authActions } from "store/slices/auth";
import TransfersHub from "./TransfersHub";

const content: Record<string, React.ReactNode> = {
  transfers: <Transfers />,
  transfersHub: <TransfersHub />,
  limits: <Limits />,
  history: <History />,
  promotion: <Promotion />,
  main: <Main />,
};

const Cards = () => {
  const dispatch = useDispatch();
  const tabsRef = useRef<null | HTMLElement>(null);
  const { activeTab, needToScrollToTabs } = useSelector(systemSelector);
  const accessToken = sessionStorage.getItem("accessToken");
  const { newMessageCounter } = useSelector(chatSelector);
  const [chatListening, setChatListening] = useState(false);

  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();

  const handleChatClick = () => {
    if (isMobile) {
      dispatch(systemActions.setActiveTab({ tab: MobileTabVariants.chat }));
    } else {
      dispatch(systemActions.setChatOpen(true));
    }
  };

  const refresh = useCallback(async () => {
    const response = await authApi.refreshToken({
      deviceId:
        (isPWA
          ? localStorage.getItem("PWADeviceId")
          : localStorage.getItem("deviceId")) || "",
    });
    const { accessToken } = response.data;
    if (accessToken) {
      sessionStorage.setItem("accessToken", accessToken);
      dispatch(authActions.setNewAccessToken(accessToken));
    }
  }, [dispatch, isPWA]);

  useEffect(() => {
    // TODO CLEANUP posle relize 2
    if (needToScrollToTabs /*  && activeTab !== "notifications" */) {
      const tabComponent = tabsRef;
      tabComponent?.current?.scrollIntoView({
        block: "start",
        inline: "end",
        behavior: "smooth",
      });
      dispatch(systemActions.setNeedScrollState(false));
    }
  }, [dispatch, needToScrollToTabs, activeTab]);

  useEffect(() => {
    if (!chatListening && accessToken) {
      const url = `${env.REACT_APP_BASE_PATH}notification/v1/chat/subscription/new-messages`;
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        heartbeatTimeout: 120000,
      };
      const es = new EventSourcePolyfill(url, options);

      es.addEventListener("open", () => {
        setChatListening(true);
      });

      es.addEventListener("message", (event) => {
        localStorage.setItem("lastKnownNewMessageCount", event.data);
        dispatch(chatActions.setNewMessageCounter(Number(event.data)));
      });

      es.addEventListener("error", (err) => {
        const lastRefreshDate = sessionStorage.getItem("lastRefreshDate");

        const isNeedRefresh = () => {
          if (!lastRefreshDate) return true;

          const dateNow = new Date();
          return (
            Math.floor(
              (new Date(dateNow).getTime() -
                new Date(lastRefreshDate).getTime()) /
                60000
            ) < 15
          );
        };

        // @ts-ignore
        if (err.status === 401 && isNeedRefresh()) {
          if (!lastRefreshDate)
            sessionStorage.setItem("lastRefreshDate", new Date().toString());
          refresh();
        }
        es.close();
        setChatListening(false);
      });
    }
  }, [dispatch, refresh, chatListening, accessToken]);

  return (
    <Box>
      {content[activeTab]}

      <Box
        sx={
          !isMobile
            ? {
                width: "fit-content",
                position: "fixed",
                bottom: "140px",
                right: "120px",
                cursor: "pointer",
              }
            : { display: "none" }
        }
        onClick={handleChatClick}
      >
        <IconChat count={newMessageCounter} />
      </Box>
    </Box>
  );
};

export default Cards;
