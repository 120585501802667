interface IconCardCvvProps extends React.SVGProps<SVGSVGElement> {}

export const IconCardCvv = (props: IconCardCvvProps) => {
  return (
    <svg
      width="186"
      height="147"
      viewBox="0 0 186 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 52.6329H184M2 27.5564C2 13.7071 13.1115 2.47998 26.8182 2.47998H159.182C172.889 2.47998 184 13.7071 184 27.5565V119.504C184 133.353 172.889 144.58 159.182 144.58H26.8182C13.1115 144.58 2 133.353 2 119.504V27.5564Z"
        stroke="#B5B5B5"
        strokeWidth="4"
      />
      <rect x="108" y="76" width="63" height="35" rx="8" fill="#E9E9E9" />
      <rect
        x="123"
        y="90"
        width="8"
        height="8"
        rx="4"
        fill={props.color ?? "#739B67"}
      />
      <rect
        x="136"
        y="90"
        width="8"
        height="8"
        rx="4"
        fill={props.color ?? "#739B67"}
      />
      <rect
        x="149"
        y="90"
        width="8"
        height="8"
        rx="4"
        fill={props.color ?? "#739B67"}
      />
    </svg>
  );
};
