import { useCallback, useState } from "react";
import { Typography, Box } from "@mui/material";

import { Button } from "components";
import { useIsMobile } from "hooks/useIsMobile";
import Notifications from "organisms/Notifications/Notifications";
import {
  Container,
  Tab,
  TabText,
  TabWrapper,
} from "pages/home/Cards/History/components/HistoryTabs/components";
import News from "organisms/Notifications/News";

type NotificationNewsModalProps = {
  onClick: () => void;
  onCancel: () => void;
};

export enum NOTIFICATION_TABS {
  NOTIFICATIONS = "Уведомления",
  NEWS = "Новости",
}

const TABS = Object.values(NOTIFICATION_TABS);

export const NotificationNewsModal = ({
  onClick,
  onCancel,
}: NotificationNewsModalProps) => {
  const { isMobile, size } = useIsMobile();
  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS.NOTIFICATIONS);

  const renderContent = useCallback(() => {
    switch (activeTab) {
      case NOTIFICATION_TABS.NOTIFICATIONS:
        return <Notifications />;
      case NOTIFICATION_TABS.NEWS:
        return <News />;
      default:
        return null;
    }
  }, [activeTab]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      px={{ xs: "16px", lg: "32px" }}
      my={12}
    >
      <Container marginTop={false}>
        <TabWrapper
          style={{
            justifyContent: "space-between",
            gap: "4px",
            flex: "1 auto",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab}
              active={activeTab === tab}
              onClick={() => {
                if (activeTab === tab) return;
                setActiveTab(tab);
              }}
              width="100%"
            >
              <TabText active={activeTab === tab}>{tab}</TabText>
            </Tab>
          ))}
        </TabWrapper>
      </Container>
      {renderContent()}
    </Box>
  );
};
