import React, { useState } from "react";
import { Box } from "@mui/material";
import theme from "theme";
import { AuthContainer } from "containers";
import { useCounter } from "hooks/useCounter";
import { authActions, checkCvv } from "store/slices/auth";
import { authSelector } from "store";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "molecules/Modal/Modal";
import { CVVModal } from "components/CVVModal/CVVModal";
import { useIsMobile } from "hooks/useIsMobile";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import BlockedScreenCVV from "organisms/Authorization/BlockedScreenCVV";
import {
  BackButtonContainer,
  SmallBackButton,
} from "components/SmallBackButton";
import { Button } from "components/Button";

const SecondCVVScreen = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>("");
  const { count } = useCounter();
  const [blocked, setBlocked] = useState(false);
  const [modaliIsOpen, setModalIsOpen] = useState(false);
  const { isMobile } = useIsMobile();
  const { isLoading, error } = useSelector(authSelector);
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
  };
  const handleConfirm = () => {
    dispatch(checkCvv(code))
      //@ts-ignore
      .unwrap()
      .catch((err) => {
        if (err.response.data.title === "Карта заблокирована") {
          setBlocked(true);
        }
      });
  };

  const handleRetryOneTimePassword = () => {
    setModalIsOpen(true);
  };

  if (blocked) {
    return <BlockedScreenCVV />;
  }

  const subtitle = isMobile
    ? `Чтобы восстановить пароль, введите\nCVV2-код карты,использованной при\nрегистрации`
    : `Чтобы восстановить пароль, введите CVV2-код карты,\nиспользованной при регистрации`;
  return (
    <>
      <BackButtonContainer>
        <SmallBackButton
          clickHandler={() => dispatch(authActions.setRestoreScreen("initial"))}
        />
      </BackButtonContainer>
      <AuthContainer
        title="Восстановить пароль"
        subtitle={subtitle}
        error={
          error?.title
            ? `${error?.title}, осталось попыток: ${error?.parameter}`
            : ""
        }
      >
        <Box
          component="form"
          autoComplete="off"
          sx={{
            backgroundColor: theme.palette.gray.b50,
          }}
          px={{ xs: 20, lg: 30.5 }}
          py={32}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          border={`1px solid ${theme.palette.gray.b100}`}
          borderRadius={24}
        >
          <Box>
            <PasswordInput
              // @ts-ignore
              value={code}
              placeholder="Введите код"
              label="CVV2-Код карты"
              onChange={handleChange}
              name="code"
              autoComplete="off"
              maxLength={3}
              error={error?.title}
            />
          </Box>
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              rowGap={count > 0 ? 44 : 12}
              mt={42}
            >
              <Button
                onClick={handleConfirm}
                variant="primary"
                disabled={code.length < 3}
                isLoading={isLoading}
                title="Продолжить"
              />
              <Button
                onClick={handleRetryOneTimePassword}
                variant="secondary"
                title="Где найти CVV2-код?"
              />
            </Box>
          </Box>
        </Box>
        <Modal
          title="Где найти CVV2?"
          isOpen={modaliIsOpen}
          onClose={() => {
            setModalIsOpen(false);
          }}
          buttonVisible={false}
          callback={() => {
            setModalIsOpen(false);
          }}
        >
          <CVVModal />
        </Modal>
      </AuthContainer>
    </>
  );
};

export default SecondCVVScreen;
