import { Box, Typography } from "@mui/material";
import theme from "theme";
import { useIsMobile } from "hooks/useIsMobile";
import { IconCardCvv } from "atoms/IconCardCvv/IconCardCvv";

export const CVVModal = () => {
  const { isMobile } = useIsMobile();

  return (
    <Box px={isMobile ? 20 : 32} height="100%" pt={isMobile ? 0 : 32}>
      <Box boxSizing="border-box">
        <Box mb={24} display="flex" flexDirection="column">
          <Typography variant="text_8" fontWeight={500}>
            CVV-код
          </Typography>
          <Typography
            variant="text_10"
            color={theme.palette.gray.b500}
            fontWeight={400}
            whiteSpace="pre-wrap"
            pt={isMobile ? 8 : 16}
            pb={isMobile ? 28 : 24}
          >
            CVV — это трехзначное число{"\n"}на обратной стороне вашей карты.
          </Typography>
          <Box
            sx={{
              background: "var(--main-color-bg-secondary)",
              borderRadius: 15,
              padding: 22,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconCardCvv />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
