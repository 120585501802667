import React, { useState, useEffect } from "react";
import { PhoneInput } from "molecules/PhoneInput/PhoneInput";
import { DateInput } from "molecules/DateInput/DateInput";
import { CardInput } from "molecules/CardInput/CardInput";
import { PhoneCodesSelect } from "molecules/PhoneCodesSelect";
import { Box } from "@mui/material";
import { AuthContainer } from "containers";
import theme from "theme";
import { PhoneCode } from "models";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { resetPassword, authActions } from "store/slices/auth";
import { authSelector } from "store";
import { useForm, Controller } from "react-hook-form";
import { formatValueByMask, getSortedCountries } from "utils";
import { fetchCountriesList } from "store/slices/auth/asyncThunks";
import { differenceInYears, isMatch } from "date-fns";
import { hideErrorMessage } from "store/slices/alerts";
import { Button } from "components/Button";

const FirstScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, error, countries } = useSelector(authSelector);
  const [phoneCode, setPhoneCode] = useState<PhoneCode | null>(null);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{
    pan: string;
    phone: string;
    birthdate: string;
  }>({
    mode: "onChange",
    defaultValues: {
      pan: "",
      phone: "",
      birthdate: "",
    },
  });

  const [pan, phone, birthdate] = watch(["pan", "phone", "birthdate"]);
  const [isDateValid, setIsDateValid] = useState("");
  const handleChangePhoneCode = (phoneCode: PhoneCode) => {
    setPhoneCode(phoneCode);
  };

  const handleResetPassword = () => {
    dispatch(
      resetPassword({
        pan,
        phone: `${phoneCode?.code}${phone}`,
        birthdate: formatValueByMask("XX-XX-XXXX", birthdate)
          .split("-")
          .reverse()
          .join("-"),
      })
    )
      //@ts-ignore
      .unwrap()
      .then(() => {
        dispatch(
          authActions.setPhone(
            phone.replace(
              /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
              "$1 (***) *** $4 $5"
            )
          )
        );
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (countries) {
      setPhoneCode(countries[0]);
    }
  }, [countries]);

  useEffect(() => {
    dispatch(fetchCountriesList());
  }, [dispatch]);

  useEffect(() => {
    const format = formatValueByMask("XX-XX-XXXX", birthdate)
      .split("-")
      .reverse()
      .join("-");
    const isValid = isMatch(format, "yyyy-MM-dd");
    const older = differenceInYears(new Date(), new Date(format)) < 14;
    if (birthdate.length === 8 && (!isValid || older)) {
      setIsDateValid("Дата указана некорректно");
    } else {
      setIsDateValid("");
    }
  }, [birthdate]);

  const handleGoBack = () => {
    dispatch(hideErrorMessage());
    dispatch(authActions.resetError());
    navigate("/sign-in");
  };

  return (
    <AuthContainer title="Восстановить пароль" subtitle="Укажите ваши данные">
      <Box
        px={{ xs: "20px", md: "30.5px" }}
        py={32}
        sx={{
          backgroundColor: theme.palette.gray.b50,
        }}
        border={`1px solid ${theme.palette.gray.b100}`}
        borderRadius={24}
      >
        <Box component="form" autoComplete="off">
          <Controller
            control={control}
            name="pan"
            render={({ field: { onChange, value } }) => (
              <CardInput
                name="cdNumber"
                label="Номер карты"
                labelColor="var(--main-color-text-title)"
                value={value}
                onChange={onChange}
                error={!!error}
              />
            )}
          />
          <Box display="flex" alignItems="center" gap={12}>
            <PhoneCodesSelect
              value={phoneCode}
              onChange={handleChangePhoneCode}
              countries={getSortedCountries(countries)}
              error={!!error}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value } }) => {
                return (
                  <PhoneInput
                    name="phoneNumber"
                    label="Номер телефона"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    mask={phoneCode?.mask}
                    codeValue={phoneCode?.code}
                    placeholder={
                      phoneCode?.code && phoneCode?.mask
                        ? phoneCode?.mask.replaceAll("x", "0")
                        : countries?.[0]?.mask?.replaceAll("x", "0")
                    }
                  />
                );
              }}
            />
          </Box>
          <Controller
            control={control}
            name="birthdate"
            render={({ field: { onChange, value } }) => (
              <DateInput
                placeholder=""
                name="birthdate"
                label="Дата рождения"
                value={value}
                onChange={onChange}
                error={!!error || !!isDateValid}
                isDateValid={isDateValid}
              />
            )}
          />
          <Box mt={64} display="flex" flexDirection="column" rowGap={12}>
            <Button
              variant="primary"
              disabled={
                Object.keys(errors).length !== 0 ||
                !pan ||
                !phone ||
                birthdate.length !== 8 ||
                !!isDateValid
              }
              isLoading={isLoading}
              onClick={handleResetPassword}
              title="Продолжить"
            />
            <Button variant="secondary" onClick={handleGoBack} title="Назад" />
          </Box>
        </Box>
      </Box>
    </AuthContainer>
  );
};

export default FirstScreen;
