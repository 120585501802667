import { Loader } from "components/Loader";
import styles from "./index.module.css";

interface Props {
  variant: "primary" | "secondary" | "text";
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Button: React.FC<Props> = ({
  variant,
  onClick,
  title,
  isLoading,
  fullWidth = true,
  disabled,
  style,
  startIcon,
  endIcon,
}) => {
  const createClassName = () => {
    switch (variant) {
      case "primary":
        return styles.primary;
      case "secondary":
        return styles.secondary;
      case "text":
        return styles.text;
    }
  };

  const className = createClassName();

  return (
    <button
      className={className}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      disabled={disabled}
      style={{
        ...style,
        pointerEvents: isLoading || disabled ? "none" : "auto",
        width: fullWidth && variant !== "text" ? "100%" : "fit-content",
      }}
    >
      {startIcon}
      {isLoading ? <Loader size={25} /> : title}
      {endIcon}
    </button>
  );
};
