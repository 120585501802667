import React, { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { IconNewsItem } from "atoms/IconNewsItem/IconNewsItem";

interface IListItem {
  title?: string;
  subTitle?: string;
  date?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
    // borderBottom: "1px solid var(--main-color-border-icon)",
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: theme.primary.main.gray[75],
    //   "& $rightIcon": {
    //     display: "inline",
    //   },
    //   "& $date": {
    //     display: "none",
    //   },
    // },
  },
  icon: {
    flexShrink: 0,
  },
  rightIcon: {
    display: "none",
  },
  date: {
    display: "inline",
  },
}));

const ListItem: FC<IListItem> = ({ title, subTitle, date, onClick }) => {
  const classes = useStyles();
  // const day = date ? format(new Date(date), "d MMMM", { locale: ru }) : "";
  const time = date ? format(new Date(date), "HH:mm") : "";

  return (
    <Box onClick={onClick} className={classes.box}>
      <Box display="flex" gap={12} width="100%">
        <IconNewsItem className={classes.icon} />
        <Box display="flex" flexDirection="column" width="100%" gap={4}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              fontSize={16}
              lineHeight="20px"
              fontWeight={400}
              color="var(--main-color-text-title)"
            >
              {title}
            </Typography>
            <Typography
              fontSize={13}
              lineHeight="14px"
              fontWeight={400}
              color="var(--main-color-notification-description)"
            >
              {time}
            </Typography>
          </Box>
          <Typography
            fontSize={13}
            lineHeight="14px"
            fontWeight={400}
            color="var(--main-color-notification-description)"
          >
            {subTitle}
          </Typography>
        </Box>
      </Box>
      {/* <Box>
        <ChevronRightIcon className={classes.rightIcon} fontSize="small" />
        <Typography className={classes.date} variant="text_2" color="gray.b400">
          {day} {time}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default ListItem;
