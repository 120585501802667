import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { authActions } from "store/slices/auth";
import { hideErrorMessage } from "store/slices/alerts";
import { Logo } from "atoms/Logo";
import { useIsMobile } from "hooks/useIsMobile";

export const UnregisteredHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useIsMobile();

  const disabledLogo =
    location.pathname === "/404" || location.pathname.includes("/refill-info");
  const isRedirectAccess =
    location.pathname === "/pin-code" || location.pathname === "/pin-enter";

  const logoClickHandler = () => {
    if (disabledLogo) {
      return null;
    }
    if (location.pathname === "/biometry-registration") {
      navigate("/", { replace: true });
    }
    if (!isRedirectAccess) {
      dispatch(hideErrorMessage());
      dispatch(authActions.resetError());
      dispatch(authActions.setRegisterScreen("initial"));
      dispatch(authActions.setRestoreScreen("initial"));
      navigate("/sign-in");
    }
  };

  return (
    <Grid
      container
      px={{ xs: 16, sm: 16, md: 34, lg: 34, xl: 86 }}
      alignItems="center"
      justifyContent={isMobile ? "space-between" : "flex-start"}
      boxSizing="border-box"
      maxWidth={1440}
      margin="0 auto"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        py: { xs: 22, lg: 32 },
      }}
    >
      <Grid item xl={3} display="flex" alignItems="center">
        <Box height="100%">
          <Logo
            sx={{
              cursor: disabledLogo ? "auto" : "pointer",
            }}
            onClick={logoClickHandler}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
