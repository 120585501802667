import React from "react";

interface IconBtnPlusProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconBtnPlus = (props: IconBtnPlusProps) => {
  const { color = "#739B67", size = 20 } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00008 1.66699H15.0001C16.841 1.66699 18.3334 3.15938 18.3334 5.00033V15.0003C18.3334 16.8413 16.841 18.3337 15.0001 18.3337H5.00008C3.15913 18.3337 1.66675 16.8413 1.66675 15.0003V5.00033C1.66675 3.15938 3.15913 1.66699 5.00008 1.66699ZM10.6251 10.6253H13.3334C13.6786 10.6253 13.9584 10.3455 13.9584 10.0003C13.9584 9.65515 13.6786 9.37533 13.3334 9.37533H10.6251V6.66699C10.6251 6.32181 10.3453 6.04199 10.0001 6.04199C9.6549 6.04199 9.37508 6.32181 9.37508 6.66699V9.37533H6.66675C6.32157 9.37533 6.04175 9.65515 6.04175 10.0003C6.04175 10.3455 6.32157 10.6253 6.66675 10.6253H9.37508V13.3337C9.37508 13.6788 9.6549 13.9587 10.0001 13.9587C10.3453 13.9587 10.6251 13.6788 10.6251 13.3337V10.6253Z"
        fill={color}
      />
    </svg>
  );
};
