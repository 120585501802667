import React from "react";

interface IconFilterProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  active?: boolean;
}

export const IconFilter = (props: IconFilterProps) => {
  const { size = 48, color = "#739B67", active = false } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 51 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="17" fill="#F5F5F5" />
      <path
        d="M22.3333 20.1586H17.3333C16.8731 20.1586 16.5 20.5317 16.5 20.9919C16.5 21.4522 16.8731 21.8253 17.3333 21.8253H22.3333C22.7936 21.8253 23.1667 21.4522 23.1667 20.9919C23.1667 20.5317 22.7936 20.1586 22.3333 20.1586Z"
        fill="#739B67"
      />
      <path
        d="M20.6667 25.8003C19.9617 25.7981 19.3161 26.1951 19 26.8253H17.3333C16.8731 26.8253 16.5 27.1984 16.5 27.6586C16.5 28.1189 16.8731 28.492 17.3333 28.492H19C19.4128 29.2999 20.3507 29.6892 21.2143 29.4112C22.078 29.1331 22.6125 28.2698 22.4765 27.3728C22.3404 26.4757 21.5739 25.8097 20.6667 25.8003Z"
        fill="#739B67"
      />
      <path
        d="M25.4333 22.7169C26.0782 22.716 26.6757 22.3778 27.0083 21.8253H30.6667C31.1269 21.8253 31.5 21.4522 31.5 20.9919C31.5 20.5317 31.1269 20.1586 30.6667 20.1586H27.15C26.7979 19.2928 25.8575 18.8232 24.9538 19.062C24.0501 19.3007 23.4644 20.1736 23.586 21.1004C23.7076 22.0271 24.4986 22.7194 25.4333 22.7169Z"
        fill="#739B67"
      />
      <path
        d="M30.6667 26.8253H24C23.5398 26.8253 23.1667 27.1984 23.1667 27.6586C23.1667 28.1189 23.5398 28.4919 24 28.4919H30.6667C31.1269 28.4919 31.5 28.1189 31.5 27.6586C31.5 27.1984 31.1269 26.8253 30.6667 26.8253Z"
        fill="#739B67"
      />
      {active && <circle cx="45.5" cy="8.5" r="5.5" fill="#739B67" />}
    </svg>
  );
};
