import { Box, Typography } from "@mui/material";
import theme from "theme";
import { useDispatch } from "react-redux";
import { chatActions } from "store/slices/chat";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
import { IconImage } from "atoms/IconImage/IconImage";
import { IconDocument } from "atoms/IconDocument/IconDocument";

type FilesProps = {
  files: { id?: string; file: File }[];
};

const Files = ({ files }: FilesProps) => {
  const dispatch = useDispatch();

  const handleClick = (id?: string) => () => {
    if (id) {
      dispatch(chatActions.removeFile(id));
      dispatch(chatActions.clearIsFileWithErrorSize());
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={16}>
      {files.map(({ id, file }) => (
        <Box key={id} display="flex" justifyContent="space-between">
          <Box display="flex" gap={6} ml={30} alignItems="center">
            {file.type === "application/pdf" ? (
              <IconDocument />
            ) : (
              <img src={URL.createObjectURL(file)} style={{ height: "32px" }} />
            )}
            <Box display="flex" flexDirection="column">
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                width={{ xs: 250, lg: 325 }}
                variant="text_3"
                title={file.name}
                color={theme.palette.gray.b600}
              >
                {file.name}
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={handleClick(id)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <IconCircleClose width={20} height={20} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Files;
