import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconClose } from "atoms/IconClose/IconClose";
import {
  CloseWrapper,
  Container,
  Star,
  StarsWrapper,
  SubmitButton,
  Title,
  LoaderContainer,
  Icon,
  Description,
  RepeatButton,
  IconRepeat,
} from "./components";
import OutlineStar from "./assets/outline-star.png";
import FillStar from "./assets/fill-star.png";
import Emoji from "./assets/emoji-icon.png";
import Error from "./assets/error-icon.png";
import Repeat from "./assets/repeat-icon.png";
import { chatActions } from "store/slices/chat";
import { Loader } from "components";
import { makeRating } from "store/slices/notifications";
import { chatSelector } from "store";
import { IconAlertTriangle } from "atoms/IconAlertTriangle/IconAlertTriangle";
import { IconWrapper } from "atoms/IconWrapper";
import { IconRefresh } from "atoms/IconRefresh/IconRefresh";

enum OperatorRatingSteps {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface ErrorStepProps {
  closeHandler: () => void;
  submitHandler: () => void;
}

interface InitialStepProps extends ErrorStepProps {
  indexCurrentStar: number | null;
  setIndexCurrentStar: (index: number | null) => void;
  operatorRatingTitle: string;
}

const STARS = Array(5).fill("none");

const InitialStep = ({
  submitHandler,
  closeHandler,
  indexCurrentStar,
  setIndexCurrentStar,
  operatorRatingTitle,
}: InitialStepProps) => {
  const starHandler = (index: number) => {
    if (index === indexCurrentStar) {
      setIndexCurrentStar(null);
    } else {
      setIndexCurrentStar(index);
    }
  };

  return (
    <Container>
      <CloseWrapper onClick={closeHandler}>
        <IconClose width={20} height={20} />
      </CloseWrapper>
      <Title>{operatorRatingTitle}</Title>
      <StarsWrapper>
        {STARS.map((_, index) => {
          let icon = OutlineStar;
          if (indexCurrentStar !== null) {
            icon = index < indexCurrentStar + 1 ? FillStar : OutlineStar;
          }
          return (
            <Star key={index} src={icon} onClick={() => starHandler(index)} />
          );
        })}
      </StarsWrapper>
      <SubmitButton
        disabled={indexCurrentStar === null}
        onClick={submitHandler}
      >
        Отправить
      </SubmitButton>
    </Container>
  );
};

const LoadingStep = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
};

const SuccessStep = ({
  closeHandler,
  description,
}: Pick<ErrorStepProps, "closeHandler"> & { description: string }) => {
  // useEffect(() => {
  //   setTimeout(() => closeHandler(), 3000);
  // }, [closeHandler]);

  return (
    <Container>
      <CloseWrapper onClick={closeHandler}>
        <IconClose width={20} height={20} />
      </CloseWrapper>
      <Icon src={Emoji} />
      <Title>Спасибо за отзыв!</Title>
      <Description>{description}</Description>
    </Container>
  );
};

const ErrorStep = ({ closeHandler, submitHandler }: ErrorStepProps) => {
  return (
    <Container>
      <CloseWrapper onClick={closeHandler}>
        <IconClose width={20} height={20} />
      </CloseWrapper>
      <IconWrapper borderRadius="100px" bgColor="#FFF4F4">
        <IconAlertTriangle />
      </IconWrapper>
      <div>
        <Title>Ошибка отправки</Title>
        <Description>Вы помогаете нам стать лучше</Description>
      </div>

      <RepeatButton onClick={submitHandler}>
        <IconRefresh color="#739B67" size={20} />
        Повторить
      </RepeatButton>
    </Container>
  );
};

const OperatorRating = () => {
  const dispatch = useDispatch();
  const { idRatingMessage, operatorRatingTitle } = useSelector(chatSelector);
  const [indexCurrentStar, setIndexCurrentStar] = useState<number | null>(null);
  const [successText, setSuccessText] = useState<string>("");
  const [step, setStep] = useState<OperatorRatingSteps>(
    OperatorRatingSteps.INITIAL
  );

  const closeHandler = useCallback(() => {
    dispatch(
      makeRating({
        rating: "0",
        messageId: idRatingMessage,
      })
    );
    dispatch(chatActions.setShowOperatorRating(false));
  }, [dispatch, idRatingMessage]);

  const submitHandler = () => {
    setStep(OperatorRatingSteps.LOADING);
    if (indexCurrentStar) {
      dispatch(
        makeRating({
          rating: String(indexCurrentStar + 1),
          messageId: idRatingMessage,
        })
      )
        //@ts-ignore
        .unwrap()
        .then((res) => {
          setSuccessText(res.text);
          setStep(OperatorRatingSteps.SUCCESS);
        })
        .catch(() => setStep(OperatorRatingSteps.ERROR));
    }
  };

  return (
    <>
      {step === OperatorRatingSteps.INITIAL && (
        <InitialStep
          submitHandler={submitHandler}
          closeHandler={closeHandler}
          indexCurrentStar={indexCurrentStar}
          setIndexCurrentStar={setIndexCurrentStar}
          operatorRatingTitle={
            operatorRatingTitle || "Пожалуйста, оцените консультацию"
          }
        />
      )}

      {step === OperatorRatingSteps.LOADING && <LoadingStep />}

      {step === OperatorRatingSteps.SUCCESS && (
        <SuccessStep
          description={successText || "Вы помогаете нам стать лучше"}
          closeHandler={() =>
            dispatch(chatActions.setShowOperatorRating(false))
          }
        />
      )}

      {step === OperatorRatingSteps.ERROR && (
        <ErrorStep closeHandler={closeHandler} submitHandler={submitHandler} />
      )}
    </>
  );
};

export default OperatorRating;
