import { forwardRef, useState } from "react";
import { CustomKeyboard } from "components/CustomKeyboard/CustomKeyboard";

import Box from "@mui/material/Box";
import { InputAdornment, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { Label } from "molecules/Label/Label";
import DisabledCard from "./DisabledCard.png";
import ActiveCard from "./ActiveCard.png";
import { makeStyles } from "@mui/styles";
import theme from "theme";
import { useIsMobile } from "hooks/useIsMobile";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .MuiInputBase-root input": {
      "&::placeholder": {
        opacity: 1,
        color: "var(--main-color-text-secondary)",
        fontSize: "16px",
        fontWeight: 500,
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
      boxSizing: "border-box",
      height: "64px",

      "& fieldset": {
        border: "none",
      },

      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.palette.indigo.b300}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "22px 16px",
      fontSize: "17px",
      lineHeight: "20px",
      fontWeight: 500,
      background: "var(--main-color-bg-widgets)",
      color: "var(--main-color-text-title)",
      borderRadius: "16px",

      boxSizing: "border-box",
      height: "64px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "var(--error-color-primary)",
      fontSize: "14px",
      lineHeight: "20px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid var(--error-color-illustration) !Important",
    },
  },
  labelRoot: {
    "& .makeStyles-labelRoot-21.MuiInputLabel-root ": {
      marginBottom: 0,
    },
  },
}));

const CardFormatInput = forwardRef(function CardFormatInput(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="#### #### #### #### ###"
      getInputRef={ref}
      allowEmptyFormatting={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

export const CardInput = ({
  name,
  label,
  sublabel = "",
  labelColor = theme.palette.gray.b600,
  subLabelColor = "var(--main-color-text-secondary)",
  value = "",
  onChange,
  placeholder = "Введите номер карты",
  icon = false,
  error = false,
  errorMessage = "",
  mb = 8,
  transfer = false,
  showCustomKeyboard = false,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const { isMobile } = useIsMobile();

  const handleKeyPress = (key) => {
    if (key === "Backspace") {
      setInputValue(inputValue.slice(0, -1));
    } else {
      if (inputValue.length > 15) return;
      setInputValue(inputValue + key);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const showKeyBoard = showCustomKeyboard && isMobile;

  return (
    <Box mb={mb}>
      <Label
        label={label}
        htmlFor={name}
        style={{
          marginBottom: 0,
          color: labelColor,
          fontWeight: transfer ? 400 : 500,
          fontSize: "16px",
        }}
      />
      <Label
        label={sublabel}
        htmlFor={name}
        style={{
          marginBottom: 8,
          color: subLabelColor,
          fontSize: "14px",
          fontWeight: transfer ? 400 : 500,
        }}
      />

      <TextField
        classes={{ root: classes.inputRoot }}
        name={name}
        onChange={showKeyBoard ? handleInputChange : onChange}
        value={showKeyBoard ? inputValue : value}
        onFocus={() => setShowKeyboard(true)}
        onClick={() => setShowKeyboard(true)}
        placeholder={placeholder}
        readOnly
        error={!!error}
        autoComplete="off"
        type="tel"
        sx={{
          "& > .MuiOutlinedInput-root > .MuiInputAdornment-root > p": {
            color: theme.palette.gray.b400,
          },
        }}
        InputProps={{
          readOnly: showKeyBoard,
          inputComponent: CardFormatInput,
          startAdornment: icon && (
            <InputAdornment position="start">
              {value ? (
                <Box component="img" src={ActiveCard} />
              ) : (
                <Box component="img" src={DisabledCard} />
              )}
            </InputAdornment>
          ),
        }}
        id={name}
        fullWidth
        helperText={errorMessage}
      />
      {showKeyboard && showKeyBoard && (
        <CustomKeyboard
          onKeyPress={handleKeyPress}
          onClose={() => setShowKeyboard(false)}
        />
      )}
    </Box>
  );
};
