import { Box, Hidden } from "@mui/material";
import { AvatarAndName } from "molecules/AvatarAndName/AvatarAndName";
import { NotificationAndNews } from "molecules/NotificationAndNews";
import { Exit } from "molecules/Exit/Exit";
import Navigation from "../Navigation/Navigation";

import { MobileTabVariants, systemActions } from "store/slices/system";
import { Logo } from "atoms/Logo";
import { useState, useEffect } from "react";
import { throttle } from "./../../utils/throttle.ts";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

import { systemSelector } from "store";
import { TabVariants } from "store/slices/system";
import { useLocation } from "react-router-dom";

export const Header = () => {
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);
  const { activeTab } = useSelector(systemSelector);

  const isMatch = useMediaQuery("(max-width:768px)");
  const location = useLocation();

  const logoHandler = () =>
    dispatch(
      systemActions.setActiveTab({
        tab: MobileTabVariants.main,
      })
    );

  useEffect(() => {
    const handleScroll = throttle(() => {
      // баг - не работает если если изменить точку отчета скрола 768 пс брекпоинт
      const scroll = isMatch ? document.body.scrollTop : window.scrollY;
      if (scroll > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }, 100);

    window.addEventListener("scroll", handleScroll, { capture: true });

    return () => {
      window.removeEventListener("scroll", handleScroll, { capture: true });
    };
  }, [isMatch]);

  const isDesktop = useMediaQuery("(min-width:1024px)");
  let isHeaderVisible = true;

  if (location.pathname === "/" && activeTab !== TabVariants.main) {
    isHeaderVisible = isDesktop ? true : false;
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: isHeaderVisible ? "1000" : "-1",
          visibility: isHeaderVisible ? "visible" : "hidden",
          backgroundColor: scrolled
            ? "rgba(255, 255, 255, 0.76)"
            : "transparent",
          transition: "background-color 0.3s ease",
          backdropFilter: scrolled ? "blur(20px)" : "",

          display: "flex",
          py: { xs: 24, lg: 20 },
          px: { xs: 16, lg: 0 },
          justifyContent: "center",
        }}
      >
        <Box
          maxWidth={1200}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Hidden lgDown>
            <Logo onClick={logoHandler} />
          </Hidden>

          <Hidden lgDown>
            <Navigation />
          </Hidden>

          <Box display="flex" alignItems="center">
            <NotificationAndNews />
            <AvatarAndName />
            <Exit />
          </Box>
        </Box>
      </Box>
    </>
  );
};
