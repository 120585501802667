import { useDispatch, useSelector } from "react-redux";
import { notificationSelector, systemSelector } from "store/selectors";
import { systemActions } from "store/slices/system";

import styles from "./styles.module.css";

import { IconBell } from "atoms/IconBell/IconBell";
import { useAppThunkDispatch } from "store";
import {
  getNewsList,
  getNotificationList,
  notificationActions,
} from "store/slices/notifications";
import { useEffect, useState } from "react";
import { profileActions } from "store/slices/profile";
import { ProfileModalType } from "pages/profile/models";

export const NotificationAndNews = () => {
  const dispatch = useDispatch();
  const { notifications, news } = useSelector(notificationSelector);
  const thunkDispatch = useAppThunkDispatch();

  const getNotifications = () => {
    thunkDispatch(getNotificationList({ page: 0, size: 1 }));
  };

  const getNews = () => {
    thunkDispatch(getNewsList({ page: 0, size: 1 }));
  };

  useEffect(() => {
    getNotifications();
    getNews();
    dispatch(notificationActions.setReadNotifications());

    return () => {
      dispatch(notificationActions.clearNotifications());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const hasNotifications = Object.keys(notifications).length > 0;
  const hasNews = Object.keys(news).length > 0;

  const hasAnyEntries = hasNotifications || hasNews;

  const handleNotification = () => {
    dispatch(systemActions.setNewAndNotificationsOpen(true));
  };

  return (
    <div className={styles.notification} onClick={handleNotification}>
      <IconBell />
      {hasAnyEntries && <span />}
    </div>
  );
};
