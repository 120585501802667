import React from "react";

export const TransferOut = ({ fill = "#101828" }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5253 15.6619C23.5253 13.8663 21.3133 12.9418 19.9932 14.1355L11.7624 22.61C10.9664 23.4041 10.9664 24.7087 11.773 25.5135L19.9488 33.9344C21.3133 35.171 23.5253 34.2465 23.5253 32.4509V28.8093H29.2288C29.7537 28.8093 30.1793 28.3837 30.1793 27.8587C30.1793 27.3337 29.7537 26.9081 29.2288 26.9081H22.4797C22.0072 26.9081 21.6241 27.2912 21.6241 27.7637V32.4509C21.6241 32.5723 21.3847 32.6724 21.2683 32.5672L13.1264 24.1785C13.0536 24.1057 13.0536 24.0071 13.1158 23.9451L21.3128 15.5027C21.3847 15.4404 21.6241 15.5405 21.6241 15.6619V20.9963C21.6241 21.1114 21.7174 21.2047 21.8325 21.2047H35.8828C36.4078 21.2047 36.8334 20.7791 36.8334 20.2541C36.8334 19.7291 36.4078 19.3035 35.8828 19.3035H23.5253V15.6619ZM33.9816 24.0567C33.9816 23.5317 33.5561 23.1061 33.0311 23.1061H29.2288C28.7038 23.1061 28.2782 23.5317 28.2782 24.0567C28.2782 24.5816 28.7038 25.0072 29.2288 25.0072H33.0311C33.5561 25.0072 33.9816 24.5816 33.9816 24.0567Z"
        fill={fill}
        stroke="#101828"
        strokeWidth="0.5"
      />
    </svg>
  );
};
