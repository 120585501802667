import styled from "@emotion/styled/macro";
import { useSelector } from "react-redux";
import { promotionSelector } from "store";
import { SmallBackButton } from "components/SmallBackButton";
import { useIsMobile } from "hooks/useIsMobile";
import { Loader } from "components";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { IconArrowRight } from "atoms/IconArrowRight/IconArrowRight";

const Container = styled.div`
  margin-top: 40px;

  @media (max-width: 500px) {
    margin-top: 25px;
  }
`;
const Image = styled.img`
  margin-top: 30px;
  border-radius: 26px;
  width: 1050px;

  @media (max-width: 500px) {
    width: 100%;
    margin-top: 25px;
  }
`;
const Title = styled.p`
  margin: 0;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  color: var(--main-color-text-title);
  margin-top: 20px;

  @media (max-width: 500px) {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
  }
`;
const DateP = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--main-color-text-secondary);

  @media (max-width: 500px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;
const GrayBlock = styled.p`
  display: flex;
  align-items: center;
`;
const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--main-color-text-subtitle);
`;
const CategoryDesktop = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: rgba(102, 112, 133, 1);
  margin-bottom: 0;
`;
const DesktopButton = styled.a`
  border-radius: 12px;
  padding: 16px;
  background: var(--main-color-bg-widgets);
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--brand-color-primary);
  margin-top: 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: fit-content;
`;
const MobileButton = styled.a`
  border-radius: 12px;
  padding: 16px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  display: flex;
  align-items: center;
  background: var(--brand-color-primary);
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: center;
  text-decoration: none;
`;
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 1200px;

  @media (max-width: 1440px) {
    width: 100%;
  }
`;

const Promotion = () => {
  const { activePromotion, loading } = useSelector(promotionSelector);
  const { isMobile } = useIsMobile();
  const date = activePromotion.dateFrom
    ? format(new Date(activePromotion.dateFrom), "dd MMMM yyyy", { locale: ru })
    : "";
  if (loading)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  return (
    <Container>
      <SmallBackButton />
      <Title>{activePromotion.title}</Title>
      {!isMobile && (
        <CategoryDesktop>{activePromotion.category}</CategoryDesktop>
      )}
      <Image src={activePromotion.srcImgDetail} />
      <GrayBlock>
        <DateP style={{ marginRight: "10px" }}>{date}</DateP>
        {isMobile && (
          <>
            <DateP>•</DateP>
            <DateP style={{ marginLeft: "10px" }}>
              {activePromotion.category}
            </DateP>
          </>
        )}
      </GrayBlock>
      {activePromotion.description && (
        <Description
          dangerouslySetInnerHTML={{ __html: activePromotion.description }}
        />
      )}
      {activePromotion.buttonLink && activePromotion.buttonName && (
        <>
          {isMobile ? (
            <MobileButton href={activePromotion.buttonLink} target="_blank">
              {activePromotion.buttonName}
              <IconArrowRight color="#FFFFFF" style={{ marginLeft: "7px" }} />
            </MobileButton>
          ) : (
            <DesktopButton href={activePromotion.buttonLink} target="_blank">
              {activePromotion.buttonName}
              <IconArrowRight style={{ marginLeft: "7px" }} />
            </DesktopButton>
          )}
        </>
      )}
    </Container>
  );
};

export default Promotion;
